import { PageTab } from '@components/layout/Routes'
import { useRouter } from 'next/router'
import Link from 'next/link'

const Tabs = ({ tabs }: { tabs: Array<PageTab> }) => {
    const { asPath } = useRouter()

    return (
        <nav className="space-x-10">
            {tabs.map((tab) => (
                <Link key={tab.route} href={`/${tab.route}`} className={`${asPath.includes(tab.route) ? 'text-primary font-semibold' : 'text-black font-medium'} text-sm uppercase hover:text-primary hover:font-semibold`}>{tab.title}</Link>
            ))}
        </nav>
    )
}

export default Tabs
