import { PageTab } from '@components/layout/Routes'
import Link from 'next/link'

const Tabs = ({ tabs }: { tabs: Array<PageTab> }) => {
    return (
        <nav className="flex flex-wrap justify-center border-t border-b" aria-label="Footer">
            {tabs.map((tab) => (
                <div key={tab.route} className="px-5 py-2">
                    <Link href={`/${tab.route}`} className="uppercase text-md md:text-sm text-gray-800 font-normal hover:text-primary hover:font-medium">{tab.title}</Link>
                </div>
            ))}
        </nav>
    )
}

export default Tabs
