import PopoverCloseButton from '@components/layout/header/PopoverCloseButton'
import Logo from '@components/layout/Logo'
import PopoverTabs from '@components/layout/header/PopoverTabs'
import SocialIcons from '@components/layout/SocialIcons'
import { PageTab } from '@components/layout/Routes'

const Panel = ({ tabs, close }: { tabs: Array<PageTab>; close: (ref?: HTMLElement) => void }) => {
    return (
        <div tabIndex={0} className="px-5 rounded-lg shadow-lg focus:outline-none bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6">
                <div className="flex items-center justify-between">
                    <div className="relative top-1 left-1">
                        <Logo />
                    </div>
                    <PopoverCloseButton />
                </div>
                <div className="mt-6">
                    <PopoverTabs tabs={tabs} close={close} />
                </div>
            </div>
            <div className="flex justify-center space-x-12 py-8">
                <SocialIcons className="w-8" />
            </div>
        </div>
    )
}

export default Panel
