import { FC, SVGProps } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { LocationMarkerIcon, ShoppingBagIcon, ColorSwatchIcon } from '@heroicons/react/outline'

export type PageTab = {
    title: string
    route: string
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
}

const Routes = ({ children }: { children: FC<Array<PageTab>> }): JSX.Element => {
    const { t } = useTranslation('common')

    const pageTabs: Array<PageTab> = [
        {
            title: t('layout.tabs.shops'),
            route: t('routes.shops'),
            icon: ShoppingBagIcon,
        },
        {
            title: t('layout.tabs.hotel'),
            route: t('routes.hotel'),
            icon: ColorSwatchIcon,
        },
        {
            title: t('layout.tabs.opening-access'),
            route: t('routes.opening-access'),
            icon: LocationMarkerIcon,
        },
    ]

    return <div>{children(pageTabs)}</div>
}

export default Routes
