
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import type { AppProps } from 'next/app'
import Layout from '@components/layout/Layout'
import PlausibleProvider from 'next-plausible'
import 'globals.css'
import { NextPage } from 'next'
import { ReactElement } from 'react'
import RetailparkContext from '@components/context/RetailparkContext'

export type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactElement<any, any> | null
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const getLayout = Component.getLayout

    //@ts-ignore
    const { retailPark } = pageProps

    return (
        <PlausibleProvider domain={process.env.NEXT_PUBLIC_VERCEL_DOMAIN || ''} trackOutboundLinks={true}>
                    <RetailparkContext.Provider value={retailPark}>
                        {getLayout ? (
                            getLayout(<Component {...pageProps} />)
                        ) : (
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        )}
                    </RetailparkContext.Provider>
        </PlausibleProvider>
    )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  