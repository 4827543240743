const hoistNonReactStatics = require('hoist-non-react-statics')

module.exports = {
    locales: ['en', 'fr'],
    defaultLocale: 'fr',
    localeDetection: false,
    staticsHoc: hoistNonReactStatics,
    pages: {
        '*': ['common', 'iconic'],
    },
}
