import parse from 'html-react-parser'
import SocialIcons from '@components/layout/SocialIcons'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'
import RetailparkContext from '@components/context/RetailparkContext'

const Sections = () => {
    const { address } = useContext(RetailparkContext)
    const { t } = useTranslation('iconic')

    return (
        <div className="flex flex-col text-center space-y-2 uppercase font-light text-lg md:flex-row md:justify-evenly md:text-left md:space-y-0 md:text-base">
            <section>
                <h1 className="mt-0 md:mt-4 mb-4 font-medium">{t('layout.come-to-iconic')}</h1>
                {address && <address className="text-sm not-italic leading-6">{parse(address)}</address>}
            </section>
            <section>
                <h1 className="my-4 font-medium">{t('layout.more-about-iconic')}</h1>
                    <Link href={`/${t('common:routes.legal-notice')}`} className="block my-3 md:my-2 uppercase font-light text-sm hover:text-primary hover:font-medium">{t('layout.legal-notice')}</Link>
            </section>
            <section>
                {/* <h1 className="mt-8 mb-4 md:mt-5 md:mb-1 font-medium">{t('layout.follow-us')}</h1>*/}
                <div className="flex justify-center space-x-8">
                    <SocialIcons className="w-8 md:w-6" />
                </div>
            </section>
        </div>
    )
}

export default Sections
